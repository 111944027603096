import { TextField } from '@mui/material'
import * as React from 'react'
import { TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

type TimePickerCellProps = {
	timeValue: Date | null
	onChange: (newValue: Date) => void
	canEdit: boolean
}

const TimePickerCell = ({ timeValue, onChange, canEdit }: TimePickerCellProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isDirty, setIsDirty] = useState<boolean>(false)
	const [localValue, setLocalValue] = useState<Date | null>(timeValue)
	const [isAccepted, setIsAccepted] = useState<boolean>(false)

	useEffect(() => {
		if (isAccepted) {
			if (onChange && isDirty && localValue) onChange(localValue)
			setIsDirty(false)
			setIsOpen(false)
			setIsAccepted(false)
		}
	}, [isAccepted])

	if (isOpen && canEdit) {
		return (
			<TimePicker
				views={['hours', 'minutes']}
				value={timeValue}
				onChange={(value: Date | null) => {
					setLocalValue(value)
					setIsDirty(true)
				}}
				onAccept={(value) => {
					setLocalValue(value)
					setIsAccepted(true)
				}}
				renderInput={(props) => <TextField {...props} />}
				closeOnSelect={true}
			/>
		)
	} else {
		return (
			<div
				onClick={() => {
					if (canEdit) setIsOpen(true)
				}}
			>
				{dayjs(timeValue).utc(true).format('h:mm A')}
			</div>
		)
	}
}

export default TimePickerCell
