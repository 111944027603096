import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { Employee } from '../../../types/Employee/Employee'
import { StyledTableCell } from '../../core/StyledTableCell'
import { ShiftEndForemanRow } from './ShiftEndForemanRow'

type SelectForemanProps = {
	foremen: Employee[] | undefined
}

export function ShiftEndSelectForeman({ foremen }: SelectForemanProps) {
	return (
		<>
			<TableContainer sx={{ height: '100%', width: '100%', overflowX: 'scroll' }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<StyledTableCell align='left'>Name</StyledTableCell>
							<StyledTableCell align='center'>Employee Id</StyledTableCell>
							<StyledTableCell align='center'>Primary Craft</StyledTableCell>
							<StyledTableCell align='center'>Worker Class</StyledTableCell>
							<StyledTableCell align='center'>Status</StyledTableCell>
							<StyledTableCell align='center'>Report Uploaded</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{(foremen ?? []).map((foreman: Employee) => (
							<ShiftEndForemanRow foreman={foreman} key={foreman.id} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
