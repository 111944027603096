/* eslint-disable quotes */
import dayjs from 'dayjs'
import { Craft } from '../types/Project/Craft'
import { DowBool, ProjectViewModel } from '../types/Project/ProjectViewModel'
import { ProjectInfo } from '../types/Project/ProjectInfo'
import { getOne, getAll, post, postWithResponse, put, deleteOne, patch } from './httpService'
import { AddWITTRequest } from '../components/project/edit/AddWorkItemTaskTemplate'
import { useTokenClaims } from '../security/useTokenClaims'

const getProjectInfo = async (): Promise<ProjectInfo> => {
	const { projectId } = useTokenClaims()
	const result = await getOne<ProjectInfo>(`/Projects/${projectId}`, 'Project Info failed to load')
	return result.data
}

const getAssignedProjects = async (): Promise<ProjectInfo[]> => {
	const result = await getOne<ProjectInfo[]>(`/Projects`, '')
	return result.data
}

const getAvailableCrafts = async (): Promise<Craft[]> => {
	const result = await getAll<Craft[]>(`/Crafts`)
	return result.data
}

const convertDowToString = (dow: DowBool) => {
	let dowString = dow.Sunday ? '1' : ''
	dowString += dow.Monday ? '2' : ''
	dowString += dow.Tuesday ? '3' : ''
	dowString += dow.Wednesday ? '4' : ''
	dowString += dow.Thursday ? '5' : ''
	dowString += dow.Friday ? '6' : ''
	dowString += dow.Saturday ? '7' : ''
	return dowString
}
const createProject = async (rq: ProjectViewModel) => {
	const rqMapped = {
		title: rq.name,
		address: rq.address,
		daysPerWeek: convertDowToString(rq.daysPerWeek),
		projectNumber: rq.projectNumber,
		customer: rq.customer,
		startDate: rq.start ? dayjs(rq.start).toJSON() : undefined,
		endDate: rq.end ? dayjs(rq.end).toJSON() : undefined,
		type: rq.type,
		crafts: rq.crafts,
		roles: rq.roles,
		workDayStartTime: rq.workDayStartTime ? dayjs(rq.workDayStartTime).utc(true).toJSON() : '',
		workDayEndTime: rq.workDayEndTime ? dayjs(rq.workDayEndTime).utc(true).toJSON() : '',
	}
	return await postWithResponse<number>('/projects', rqMapped)
}

const updateProject = async (rq: ProjectViewModel) => {
	const rqMapped = {
		title: rq.name,
		address: rq.address,
		daysPerWeek: convertDowToString(rq.daysPerWeek),
		projectNumber: rq.projectNumber,
		customer: rq.customer,
		startDate: rq.start ? dayjs(rq.start).toJSON() : undefined,
		endDate: rq.end ? dayjs(rq.end).toJSON() : undefined,
		type: rq.type,
		crafts: rq.crafts,
		roles: rq.roles,
		workDayStartTime: rq.workDayStartTime ? dayjs(rq.workDayStartTime).utc(true).toJSON() : '',
		workDayEndTime: rq.workDayEndTime ? dayjs(rq.workDayEndTime).utc(true).toJSON() : '',
	}
	return await put(`/projects/${rq.id}`, rqMapped)
}

const deleteProject = async () => {
	const { projectId } = useTokenClaims()
	return await deleteOne(`/projects/${projectId}`)
}

const activateProject = async () => {
	const { projectId } = useTokenClaims()
	return await patch(`/projects/${projectId}/Activate`, {})
}

const addProjectMetadata = async (index: number, name: string, type: string) => {
	const { projectId } = useTokenClaims()
	return await post(`/projects/${projectId}/MetaData`, { index, name, type })
}

const addProjectWBSItem = async (name: string) => {
	const { projectId } = useTokenClaims()
	return await post(`/projects/${projectId}/WBS`, { name })
}

const addProjectWBSWorkItem = async (name: string, wbsId: number) => {
	return await post(`/WBS/${wbsId}/WorkItemTypes`, { name })
}

const deleteProjectMetadata = async (id: number) => {
	return await deleteOne(`/MetaData/${id}`)
}

const deleteWbsItem = async (id: number) => {
	return await deleteOne(`/WBS/${id}`)
}

const deleteWbsWorkItem = async (wbsId: number, workItemId: number) => {
	return await deleteOne(`/WBS/${wbsId}/Types/${workItemId}`)
}

const deleteSpecItem = async (id: number) => {
	return await deleteOne(`/Specs/${id}`)
}

const deleteWITT = async (specId: number, wittId: number) => {
	return await deleteOne(`/Specs/${specId}/Tasks/${wittId}`)
}

const addProjectSpecItem = async (name: string, craftId: number) => {
	const { projectId } = useTokenClaims()
	return await post(`/projects/${projectId}/Specs`, { name, craftId })
}

const addSpecWITT = async (specId: number, data: AddWITTRequest) => {
	return await post(`/specs/${specId}/Tasks`, {
		creditRule: data.creditRule,
		itpRequirement: data.itpRequirement,
		name: data.taskName,
	})
}

const listAttachments = async (workItemId: number) => {
	return await getAll(`/attachments?workItemId=${workItemId}`)
}

const getAttachment = async (attachmentId: number, workItemId: number) => {
	return await getAll(`/attachments/${attachmentId}?workItemId=${workItemId}`)
}

export {
	getProjectInfo,
	getAssignedProjects,
	getAvailableCrafts,
	createProject,
	updateProject,
	activateProject,
	addProjectMetadata,
	deleteProjectMetadata,
	addProjectWBSItem,
	addProjectWBSWorkItem,
	addProjectSpecItem,
	addSpecWITT,
	deleteWbsItem,
	deleteWbsWorkItem,
	deleteSpecItem,
	deleteWITT,
	getAttachment,
	listAttachments,
	deleteProject,
}
