import { createTheme, responsiveFontSizes, SimplePaletteColorOptions } from '@mui/material/styles'

import { ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/Chip' {
	interface ChipPropsVariantOverrides {
		backlogWorkItem: true
		task: true
		worker: true
		workitem: true
		Archived: true
		Active: true
		Inactive: true
		Draft: true
		workers: true
		workitems: true
		activity: true
		active: true
		failure: true
		success: true
		FieldEngineer: true
		Tracker: true
		Inspector: true
		Laborer: true
		Foreman: true
		GeneralForeman: true
		Superintendent: true
		ProjectControls: true
		SuperAdmin: true
		Succeeded: true
		Failed: true
		Processing: true
		Partial: true
		'Not Started': true
		Constrained: true
		'Pending Partial': true
		'Pending Final': true
		Passed: true
		'Failed ITP': true
		Complete: true
		Online: true
		Offline: true
		'Pending Witness': true
		'Pending Inspection': true
		'Passed Inspection': true
		'Failed Inspection': true
		'Work Stopped': true
		Removed: true
		time: true
	}
}

const colorThemeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#141B4D',
			light: '#bbdefb',
		},
		secondary: {
			main: '#0DAEFF',
		},
		info: {
			main: '#46B2ED',
		},
		error: {
			main: '#CC0C00',
		},
		warning: {
			main: '#FCA32C',
		},
		success: {
			main: '#0DB270',
		},
	},
}

const chipThemeOptions: ThemeOptions = {
	components: {
		MuiChip: {
			styleOverrides: {
				// The props to change the default for.
				root: {
					borderRadius: '4px',
					elevation: 0,
					boxShadow: 'none',
					textTransform: 'capitalize',
					padding: 0,
					margin: 0,
					fontSize: '14px',
					fontWeight: '500',
				},
			},
			variants: [
				{
					props: { variant: 'backlogWorkItem' },
					style: {
						color: '#fff',
						background: '#F04438',
					},
				},
				{
					props: { variant: 'task' },
					style: {
						color: '#fff',
						background: '#F59E0B',
					},
				},
				{
					props: { variant: 'worker' },
					style: {
						color: '#fff',
						background: '#4FABF9'
					}
				},
				{
					props: { variant: 'activity' },
					style: {
						color: '#fff',
						background: '#755CF0'
					}
				},
				{
					props: { variant: 'time' },
					style: {
						color: '#fff',
						background: '#F59E0B',
					},
				},
				{
					props: { variant: 'workers' },
					style: {
						background: '#EBE9FE',
						color: '#7A5AF8',
					},
				},
				{
					props: { variant: 'workitems' },
					style: {
						background: '#E0F2FE',
						color: '#0BA5EC',
					},
				},
				{
					props: { variant: 'Active' },
					style: {
						background: '#D1FADF',
						color: (colorThemeOptions.palette?.success as SimplePaletteColorOptions).main,
						padding: 0,
					},
				},
				{
					props: { variant: 'Inactive' },
					style: {
						background: '#FEDDDD',
						color: '#EF4444',
						padding: 0,
					},
				},
				{
					props: { variant: 'Archived' },
					style: {
						background: '#FEDDDD',
						color: '#EF4444',
						padding: 0,
					},
				},
				{
					props: { variant: 'Draft' },
					style: {
						background: (colorThemeOptions.palette?.info as SimplePaletteColorOptions).main,
						color: '#fff',
						padding: 0,
					},
				},
				{
					props: { variant: 'failure' },
					style: {
						background: '#FEDDDD',
						color: '#EF4444',
					},
				},
				{
					props: { variant: 'success' },
					style: {
						background: '#D1FADF',
						color: (colorThemeOptions.palette?.success as SimplePaletteColorOptions).main,
						padding: 0,
					},
				},
				{
					props: { variant: 'FieldEngineer' },
					style: {
						backgroundColor: '#0DAEFF',
					},
				},
				{
					props: { variant: 'Tracker' },
					style: {
						backgroundColor: '#D9BA21',
					},
				},
				{
					props: { variant: 'Inspector' },
					style: {
						backgroundColor: '#676767',
					},
				},
				{
					props: { variant: 'Laborer' },
					style: {
						backgroundColor: '#12B76A',
					},
				},
				{
					props: { variant: 'Foreman' },
					style: {
						backgroundColor: '#F59E0B',
					},
				},
				{
					props: { variant: 'GeneralForeman' },
					style: {
						backgroundColor: '#F04438',
					},
				},
				{
					props: { variant: 'Superintendent' },
					style: {
						backgroundColor: '#0DAEFF',
					},
				},
				{
					props: { variant: 'ProjectControls' },
					style: {
						backgroundColor: '#7A5AF8',
					},
				},
				{
					props: { variant: 'SuperAdmin' },
					style: {
						backgroundColor: '#0DAEFF',
					},
				},
				{
					props: { variant: 'Succeeded' },
					style: {
						backgroundColor: '#12B76A',
					},
				},
				{
					props: { variant: 'Failed' },
					style: {
						backgroundColor: '#EF4444',
					},
				},
				{
					props: { variant: 'Processing' },
					style: {
						backgroundColor: '#0DAEFF',
					},
				},
				{
					props: { variant: 'Partial' },
					style: {
						backgroundColor: '#D9BA21',
					},
				},
				{
					props: { variant: 'Not Started' },
					style: {},
				},
				{
					props: { variant: 'Constrained' },
					style: {},
				},
				{
					props: { variant: 'Pending Partial' },
					style: {},
				},
				{
					props: { variant: 'Pending Final' },
					style: {},
				},
				{
					props: { variant: 'Passed' },
					style: {},
				},
				{
					props: { variant: 'Failed ITP' },
					style: {},
				},
				{
					props: { variant: 'Complete' },
					style: {},
				},
				{
					props: { variant: 'Offline' },
					style: {
						backgroundColor: '#FEDDDD',
						color: '#EF4444',
					},
				},
				{
					props: { variant: 'Online' },
					style: {
						backgroundColor: '#D1FADF',
						color: '#12B76A',
					},
				},
			],
		},
	},
}
const componentThemeOptions: ThemeOptions = {
	components: {
		// Name of the components
		MuiButton: {
			styleOverrides: {
				// The props to change the default for.
				root: {
					borderRadius: '4px',
					elevation: 0,
					boxShadow: 'none',
					textTransform: 'capitalize',
					color: 'white',
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingBottom: 0,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: (colorThemeOptions.palette?.primary as SimplePaletteColorOptions).main,
						color: 'white',
						'& .MuiListItemIcon-root': {
							color: 'white',
						},
					},
					width: '245px',
					padding: 0,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 'inherit',
					width: '36px',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					marginLeft: '30px',
					marginRight: '-20px',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					whiteSpace: 'nowrap',
				},
			},
		},
	},
}

const componentThemeOptions2: ThemeOptions = {
	components: {
		MuiListItem: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: 'none',
						color: 'inherit',
						'& .MuiListItemIcon-root': {
							color: 'inherit',
						},
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 'inherit',
					width: '36px',
				},
			},
		},
	},
}

const sideNavBarThemeOptions: ThemeOptions = {
	components: {
		MuiList: {
			styleOverrides: {
				root: {
					marginTop: '15px',
					paddingTop: 0,
					paddingBottom: 0,
					margin: '15px 5px',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: '#262626',
						background: 'none',
						'& .MuiListItemIcon-root': {
							color: '#262626',
						},
					},
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: '#262626',
						background: '#dddddd',
						'& .MuiListItemIcon-root': {
							color: '#262626',
						},
					},
					padding: '0px 10px',
					margin: 0,
					gap: '5px',
					fontWeight: 500,
					borderRadius: '8px',
					width: '239px',
					color: '#262626',
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 'inherit',
					width: '36px',
					color: '#262626',
				},
			},
		},
	},
}
const getSideNavBarTheme = () => {
	const fullTheme = createTheme(colorThemeOptions, chipThemeOptions, sideNavBarThemeOptions)
	return responsiveFontSizes(fullTheme)
}

const getDefaultTheme = () => {
	const fullTheme = createTheme(componentThemeOptions, colorThemeOptions, chipThemeOptions)
	return responsiveFontSizes(fullTheme)
}

const getHoverlessListTheme = () => {
	const fullTheme = createTheme(componentThemeOptions2, colorThemeOptions, chipThemeOptions)
	return responsiveFontSizes(fullTheme)
}

export { getDefaultTheme, getHoverlessListTheme, getSideNavBarTheme }
