import * as React from 'react'
import './styles.scss'
import { useEffect, useState } from 'react'
import SearchBar from '../components/workitemsoverview/SearchBar'
import { WorkerGrid } from '../components/reports/shift-end/workersummary/WorkerGrid'
import { confirmTimeSheet } from '../services/shiftEndReportService'
import ConfirmDialog from '../components/core/ConfirmDialog'
import { showSuccessToast } from '../services/alertServices'
import { useShiftEndContext } from '../components/reports/shift-end/ShiftEndContext'
import { ApiBaseResponse } from '../services/httpService'
import { useParams } from 'react-router-dom'
import { useDecodeToken } from '../security/useDecodeToken'

const ShiftEndWorkersPartialPage = () => {
	const token = useDecodeToken()
	const params = useParams()
	const viewAsId = token?.role === 'Foreman' ? Number(token.nameid) : Number(params.id)

	const { shiftDate, filteredData, loadData, setSearchText, setIncludeAllTasks } =
		useShiftEndContext()

	const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState<boolean>(false)
	// Id of the of Timesheet where "confirm" was clicked. Set in showConfirmDialog()
	const [clickedTimeSheet, setClickedTimeSheet] = useState<number>(0)

	const handleConfirm = async () => {
		const result: ApiBaseResponse = await confirmTimeSheet(clickedTimeSheet)
		// API success will reload page data, returned error will display automatically in a toast.
		if (result.success) {
			showSuccessToast('Timesheet Confirmed')
			loadData(viewAsId)
		}
		setIsConfirmDialogVisible(false)
	}
	// Executes when "cancel" is clicked on confirm timesheet dialog.
	const handleCancel = () => {
		setIsConfirmDialogVisible(false)
	}

	const showConfirmDialog = (timeSheetId: number) => {
		setClickedTimeSheet(timeSheetId)
		setIsConfirmDialogVisible(true)
	}

	useEffect(() => {
		;(async () => {
			await loadData(viewAsId)
		})()
	}, [shiftDate])

	const handleOnSearchChange = (searchText: string, includeAllTasks: boolean) => {
		setSearchText(searchText)
		setIncludeAllTasks(includeAllTasks)
	}

	return (
		<>
			<SearchBar onChange={handleOnSearchChange} searchPlaceholder='Filter Timesheets' />
			<WorkerGrid timesheets={filteredData} openDialog={showConfirmDialog} />
			<ConfirmDialog
				title={'Confirm Timesheet'}
				cancelButtonText={'Cancel'}
				confirmButtonText={'Confirm'}
				visible={isConfirmDialogVisible}
				onCancel={handleCancel}
				onConfirm={handleConfirm}
			>
				Confirm timesheet for the selected worker. Once timesheet is submitted it CANNOT be changed.
			</ConfirmDialog>
		</>
	)
}

export default ShiftEndWorkersPartialPage
