import { Chip, IconButton, TableRow } from '@mui/material'
import * as React from 'react'
import { StyledTableCell } from '../../../core/StyledTableCell'
import {
	ShiftEndTaskSummary,
	ShiftEndWorkItemsSummary,
} from '../../../../types/Reports/ShiftEnd/ShiftEndWorkItems'
import { formatPercent } from '../../../../services/DataFormatters'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'

const WorkerSummaryRow = ({ workItem }: { workItem: ShiftEndWorkItemsSummary }) => {
	const [open, setOpen] = useState(false)
	return (
		<>
			<TableRow key={workItem.workerId} sx={{ cursor: 'pointer' }}>
				<StyledTableCell>
					{(workItem?.tasks?.length ?? 0) > 0 && (
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip label={workItem.type} variant={'backlogWorkItem'} />
				</StyledTableCell>
				<StyledTableCell align='center'>{workItem.title}</StyledTableCell>
				<StyledTableCell align='center'>{workItem.quantityBudgeted}</StyledTableCell>
				<StyledTableCell align='center'>{workItem.burnedHours}</StyledTableCell>
				<StyledTableCell align='center'>{workItem.performanceFactor}</StyledTableCell>
				<StyledTableCell align='center'>{formatPercent(workItem.percentComplete)}</StyledTableCell>
				<StyledTableCell align='center'>{workItem.budgetedHours}</StyledTableCell>
				<StyledTableCell align='center'>{workItem.status}</StyledTableCell>
			</TableRow>
			{open && (
				<>
					{(workItem.tasks ?? []).map((task: ShiftEndTaskSummary, index) => (
						<TableRow key={index}>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell align='center'>
								<Chip label={task.type} variant={'task'} />
							</StyledTableCell>
							<StyledTableCell align='center'>{task.title}</StyledTableCell>
							<StyledTableCell align='center'></StyledTableCell>
							<StyledTableCell align='center'>{task.burnedHours}</StyledTableCell>
							<StyledTableCell align='center'></StyledTableCell>
							<StyledTableCell align='center'>
								{formatPercent(task.percentComplete)}
							</StyledTableCell>
							<StyledTableCell align='center'></StyledTableCell>
							<StyledTableCell align='center'>{task.status}</StyledTableCell>
						</TableRow>
					))}
				</>
			)}
		</>
	)
}

export default WorkerSummaryRow
