/* eslint-disable indent */
import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useParams } from 'react-router-dom'
import {
	getTimeSheetsForSupervisor,
	updateTimeSheetActivity,
} from '../../../services/shiftEndReportService'
import { TimeSheet } from '../../../types/Reports/ShiftEnd/TimeSheet'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(tz)

import { Activity } from '../../../types/Reports/ShiftEnd/Activity'
import { ApiBaseResponse } from '../../../services/httpService'
dayjs.extend(utc)

type ShiftEndContextType = {
	shiftDate: Date
	filteredData: TimeSheet[]
	workerSummary: TimeSheet[]
	loadData: (supervisorId: number) => Promise<void>
	setShiftDate: (date: Date) => void
	setFilteredData: Dispatch<SetStateAction<TimeSheet[]>>
	setIncludeAllTasks: Dispatch<React.SetStateAction<boolean>>
	setSearchText: Dispatch<React.SetStateAction<string>>
	setWorkersTimeRecords: Dispatch<SetStateAction<TimeSheet[]>>
	updateTimeSheet: (
		timesheet: TimeSheet,
		activity: Activity,
		startTime: Date,
		endTime: Date,
	) => void
}

export const ShiftEndContext = createContext<ShiftEndContextType | null>(null)

const useShiftEndContext = (): ShiftEndContextType => {
	const shiftEndContextState = useContext(ShiftEndContext)
	if (shiftEndContextState === null) {
		throw new Error(
			'ShiftEndContext not found. Try wrapping a parent component with <ShiftEndContext.Provider>.',
		)
	}
	return shiftEndContextState
}

type ShiftEndProviderProps = {
	children?: React.ReactNode
}

const ShiftEndProvider = ({ children }: ShiftEndProviderProps) => {
	const [shiftDate, setShiftDate] = useState<Date>(new Date())
	const [filteredData, setFilteredData] = useState<TimeSheet[]>([])
	const [workerSummary, setWorkersTimeRecords] = useState<TimeSheet[]>([])
	const [searchText, setSearchText] = useState<string>('')
	const [includeAllTasks, setIncludeAllTasks] = useState<boolean>(false)

	const loadData = async (supervisorId: number) => {
		const timeZone = dayjs.tz.guess()
		if (supervisorId && shiftDate) {
			const response = await getTimeSheetsForSupervisor(supervisorId, shiftDate)
			const workerSummary: TimeSheet[] = response.data.map((item: TimeSheet) => {
				// dates should be utc by default, only format locally on display
				const { minStartTime, maxEndTime } = findStartEndTime(item)
				return {
					id: item.id,
					type: item.type,
					title: item.title,
					startTime: dayjs(minStartTime).utc(true).local().toDate(),
					endTime: dayjs(maxEndTime).utc(true).local().toDate(),
					hours: workSheetTotalHoursCalc(item.activities),
					activities: item.activities.map((activity: Activity) => ({
						id: activity.id,
						type: activity.type,
						activity: activity.activity,
						startTime: dayjs(activity.startTime).utc(true).local().toDate(),
						endTime: dayjs(activity.endTime).utc(true).local().toDate(),
						comment: activity.comment,
						hours: calculateHours(activity.startTime, activity.endTime),
					})),
					confirmed: item.confirmed,
				}
			})
			setWorkersTimeRecords(workerSummary)
		}
	}

	const workSheetTotalHoursCalc = (activities: Activity[]) => {
		return activities.reduce(
			(previousActivity: number, currentActivity: Activity) =>
				previousActivity + calculateHours(currentActivity.startTime, currentActivity.endTime),
			0,
		)
	}

	const calculateHours = (startTimeDate: Date, endTimeDate: Date) => {
		const timeVal = dayjs.duration(dayjs(endTimeDate).diff(dayjs(startTimeDate)))
		// Convert to hours rounded to 2 decimal places.
		const decimalTimeVal = (timeVal.hours() + timeVal.minutes() / 60).toFixed(2)
		return Number(decimalTimeVal)
	}

	const updateTimeSheet = async (
		timesheet: TimeSheet,
		activity: Activity,
		startTime: Date,
		endTime: Date,
	) => {
		const resp: ApiBaseResponse = await updateTimeSheetActivity(
			timesheet.id,
			activity.id,
			startTime,
			endTime,
		)
		if (resp.success) {
			// map on the timesheet, return an object, destructure activities over it
			const workerSummaryCopy = workerSummary.map((timesheet) => {
				return {
					...timesheet,
					activities: timesheet.activities.map((activity) => ({ ...activity })),
				}
			})

			const indexOfNewTimeSheet = workerSummaryCopy.findIndex((i) => i.id === timesheet.id)

			if (workerSummaryCopy[indexOfNewTimeSheet]) {
				const foundActivity = workerSummaryCopy[indexOfNewTimeSheet].activities.find(
					(el) => el.id === activity.id,
				)
				if (foundActivity) {
					foundActivity.startTime = startTime
					foundActivity.endTime = endTime
					foundActivity.hours = calculateHours(startTime, endTime)
					workerSummaryCopy[indexOfNewTimeSheet].hours = workSheetTotalHoursCalc(
						workerSummaryCopy[indexOfNewTimeSheet].activities,
					)
					const { minStartTime, maxEndTime } = findStartEndTime(
						workerSummaryCopy[indexOfNewTimeSheet],
					)
					workerSummaryCopy[indexOfNewTimeSheet].startTime = minStartTime
					workerSummaryCopy[indexOfNewTimeSheet].endTime = maxEndTime
				}
			}

			setWorkersTimeRecords(workerSummaryCopy)
		}
	}

	const findStartEndTime = (timesheet: TimeSheet) => {
		let minStartTime = dayjs(timesheet.activities[0].startTime).toDate()
		let maxEndTime = dayjs(timesheet.activities[0].endTime).toDate()

		timesheet.activities.map((activity: Activity) => {
			if (dayjs(activity.startTime).isBefore(minStartTime)) {
				minStartTime = dayjs(activity.startTime).toDate()
			}
			if (dayjs(activity.endTime).isAfter(maxEndTime)) {
				maxEndTime = dayjs(activity.endTime).toDate()
			}
		})
		return {
			minStartTime: dayjs(minStartTime).toDate(),
			maxEndTime: dayjs(maxEndTime).toDate(),
		}
	}

	// Filter grid logic.
	useEffect(() => {
		if (searchText === '') setFilteredData(workerSummary)
		else {
			const filtered = (workerSummary || []).filter(
				(x) =>
					x.title.toLowerCase().includes(searchText.toLowerCase()) ||
					x.type.toLowerCase().includes(searchText.toLowerCase()) ||
					(x.startTime
						? dayjs(x.startTime).format('h:mm A').toLowerCase().includes(searchText.toLowerCase())
						: false) ||
					(x.endTime
						? dayjs(x.endTime).format('h:mm A').toLowerCase().includes(searchText.toLowerCase())
						: false) ||
					x.activities.some(
						(t) =>
							t.activity.toLowerCase().includes(searchText.toLowerCase()) ||
							t.comment.toLowerCase().includes(searchText.toLowerCase()) ||
							(t.startTime != null
								? dayjs(t.startTime)
										.format('h:mm A')
										.toLowerCase()
										.includes(searchText.toLowerCase())
								: false) ||
							(t.endTime != null
								? dayjs(t.endTime).format('h:mm A').toLowerCase().includes(searchText.toLowerCase())
								: false),
					),
			)
			if (!includeAllTasks) {
				const childFiltered = filtered.map((timeSheet) => {
					return {
						...timeSheet,
						activities: timeSheet.activities.filter(
							(t) =>
								t.activity.toLowerCase().includes(searchText.toLowerCase()) ||
								t.comment.toLowerCase().includes(searchText.toLowerCase()) ||
								(t.startTime != null
									? dayjs(t.startTime)
											.format('h:mm A')
											.toLowerCase()
											.includes(searchText.toLowerCase())
									: false) ||
								(t.endTime != null
									? dayjs(t.endTime)
											.format('h:mm A')
											.toLowerCase()
											.includes(searchText.toLowerCase())
									: false),
						),
					}
				})
				setFilteredData(childFiltered)
			} else setFilteredData(filtered)
		}
	}, [workerSummary, searchText, includeAllTasks])

	return (
		<ShiftEndContext.Provider
			value={{
				shiftDate,
				filteredData,
				workerSummary,
				loadData,
				setShiftDate,
				setIncludeAllTasks,
				setFilteredData,
				setSearchText,
				setWorkersTimeRecords,
				updateTimeSheet,
			}}
		>
			{children}
		</ShiftEndContext.Provider>
	)
}

export { useShiftEndContext, ShiftEndProvider }
