import React, { createContext, useContext, useState } from 'react'
import {
	getForemanWork,
	unassignTask as unassignTaskService,
	assignTask as assignTaskService,
	activateTaskStatus,
	deactivateTaskStatus,
} from '../../services/backlogService'
import { Employee } from '../../types/Employee/Employee'
import { WorkItemTask } from '../../types/Project/WorkItemTask'
interface BacklogContextType {
	employee: Employee | undefined
	directReports: Employee[] | undefined
	unassignTask: (task: WorkItemTask, employee: Employee) => Promise<boolean>
	assignTask: (task: WorkItemTask, employee: Employee) => Promise<boolean | 'duplicate'>
	loadData: (id?: number) => void
	setTaskStatus: (changeToActive: boolean, workerId: number, taskId: number) => void
}

const BacklogContext = createContext<BacklogContextType | null>(null)

const useBacklogContext = (): BacklogContextType => {
	const backlogContextState = useContext(BacklogContext)
	if (backlogContextState === null) {
		throw new Error(
			'BacklogContext not found. Try wrapping a parent component with <BacklogContext.Provider>.',
		)
	}
	return backlogContextState
}

type BacklogProviderProps = {
	children?: React.ReactNode
}

const BacklogProvider = ({ children }: BacklogProviderProps) => {
	const [foremanId, setForemanId] = useState<number | undefined>()
	const [employee, setEmployee] = useState<Employee | undefined>()
	const [directReports, setDirectReports] = useState<Employee[] | undefined>([])

	const setTaskStatus = async (changeStatusTo: boolean, workerId: number, taskId: number) => {
		let result: boolean
		if (changeStatusTo) result = await activateTaskStatus(workerId, taskId)
		else result = await deactivateTaskStatus(workerId, taskId)
		await loadData()
		return result
	}

	const unassignTask = async (task: WorkItemTask, employee: Employee) => {
		const result = await unassignTaskService(task.id, employee.id)
		await loadData()
		return result
	}

	const assignTask = async (
		task: WorkItemTask,
		employee: Employee,
	): Promise<boolean | 'duplicate'> => {
		// let isDuplicate = false
		// const findEmp = directReports?.find((x) => x.id === employee.id)
		// if (findEmp && findEmp.workItems) {
		// 	isDuplicate = findEmp.workItems.some((workItem) =>
		// 		workItem.tasks.some((wit) => wit.id === task.id),
		// 	)
		// }
		// if (isDuplicate) {
		// 	return 'duplicate'
		// }

		const result = await assignTaskService(task.id, employee.id)
		await loadData()
		return result
	}

	const loadData = async (id?: number) => {
		if (id) {
			setForemanId(id)
			const { employee, directReports } = await getForemanWork(id)
			setEmployee(employee)
			setDirectReports(directReports)
		} else if (foremanId) {
			const { employee, directReports } = await getForemanWork(foremanId)
			setEmployee(employee)
			setDirectReports(directReports)
		}
	}

	return (
		<BacklogContext.Provider
			value={{ employee, directReports, unassignTask, assignTask, loadData, setTaskStatus }}
		>
			{children}
		</BacklogContext.Provider>
	)
}

export { useBacklogContext, BacklogProvider }
